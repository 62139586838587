import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Col, Container, Row } from "react-bootstrap";

import VizSensor from "react-visibility-sensor";
import * as SC from "./styles";
import { IBlockProps, IImageProps } from "../../../../@types";
import api from "../../../../services/api";
import Globals from "../../../../utils/globals";

export interface IArtistsType extends IImageProps {
  type?: string;
  active?: boolean;
}

const Artists: React.FC<IBlockProps> = (props) => {
  const numPerPage = 9;
  const [currentArtistsPage, setCurrentArtistsPage] = useState<IArtistsType[]>(
    [],
  );
  const [currentType, setCurrentType] = useState("national");
  const [currentPage, setCurrentPage] = useState(-1);
  const [artists, setArtists] = useState<IArtistsType[]>([]);
  const [shuffledArtists, setShuffledArtists] = useState<IArtistsType[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [activeArtist, setActiveArtist] = useState<IArtistsType>();

  const sectionRef = useRef<any>();
  const artistsInterval = useRef<any>();

  useEffect(() => {
    api
      .get<IArtistsType[]>("/artists", {
        params: {},
      })
      .then(({ data }) => {
        setArtists(
          data.map((artist) => ({
            ...artist,
            type:
              artist?.options?.international == 0
                ? "national"
                : "international",
          })),
        );
        console.log("artists", data);
      });
  }, []);

  useEffect(() => {
    setCurrentPage(0);

    const typedArtists = artists.filter(
      (artist) => artist.type === currentType,
    );
    const shuffled = typedArtists.sort(() => Math.random() - 0.5);
    console.log("artists", artists, "effect do setArtists", shuffled);
    setShuffledArtists(shuffled);
  }, [artists, currentType]);

  useEffect(() => {
    if (shuffledArtists?.length > 0 && currentPage >= 0) {
      const firstElement = currentPage * numPerPage;
      const lastElement = currentPage * numPerPage + numPerPage;
      console.log("slice", firstElement, lastElement);
      const sliced = shuffledArtists.slice(firstElement, lastElement);
      while (sliced.length < 9) sliced.push({});
      setCurrentArtistsPage(sliced);
    }
  }, [shuffledArtists, currentPage]);

  const goToNextPage = useCallback(() => {
    console.log("goToNextPage", shuffledArtists.length);
    setCurrentPage((page) => {
      const nextPage =
        (page + 1) * numPerPage < shuffledArtists.length ? page + 1 : 0;
      console.log("currentPage", page, "next page", nextPage);
      return nextPage;
    });
  }, [shuffledArtists]);

  const isThisArtistActive = useCallback((artist) => artist == activeArtist, [
    activeArtist,
  ]);
  const onClickArtist = useCallback((artist) => {
    setActiveArtist(artist);
  }, []);

  useEffect(() => {
    artistsInterval.current = setInterval(goToNextPage, 15000);
    return () => clearInterval(artistsInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToNextPage]);

  const skipArtists = () => {
    clearInterval(artistsInterval.current);
    goToNextPage();
    artistsInterval.current = setInterval(goToNextPage, 15000);
  };

  useLayoutEffect(() => {
    const onScroll = () => {
      // setTopPosition(sectionRef?.current?.getBoundingClientRect().top);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [sectionRef]);

  return (
    <SC.ArtistsContainer {...props} ref={sectionRef}>
      <Container>
        <Row>
          <Col xs={3} md={3}>
            <VizSensor
              minTopValue={200}
              partialVisibility
              onChange={(isVisible) => {
                console.log("highlight isVisible: ", isVisible);
                setIsVisible(isVisible);
              }}
            >
              <h2 className={isVisible ? "active" : "inactive"}>
                AR
                <br />
                TIST
                <br />
                .AS
              </h2>
            </VizSensor>
            <a className="all-but-mobile pointer" onClick={() => skipArtists()}>
              MAIS <span>+/</span>
            </a>
            <SC.Switcher>
              <SC.Switch
                selected={currentType === "international"}
                onClick={() => setCurrentType("international")}
              >
                Internacional
              </SC.Switch>
              <SC.Switch
                selected={currentType === "national"}
                onClick={() => setCurrentType("national")}
              >
                Nacional
              </SC.Switch>
            </SC.Switcher>
          </Col>
          <Col xs={9} md={9}>
            <Row style={{ paddingRight: 20 }}>
              {currentArtistsPage?.length &&
                currentArtistsPage.map((artist, index) => (
                  <Col xs={6} md={4}>
                    <SC.Artist>
                      {artist.name && (
                        <SC.ArtistName>
                          <span>{artist.name}</span>
                        </SC.ArtistName>
                      )}
                      <SC.LinksContainer active={isThisArtistActive(artist)}>
                        {artist.link && (
                          <SC.ArtistPageLink>
                            <a href={artist.link} target="_blank">
                              Página do Artista
                            </a>
                          </SC.ArtistPageLink>
                        )}
                        {artist.options?.media_kit && (
                          <SC.MidiaKitLink>
                            <a href={artist.options.media_kit} target="_blank">
                              Mídia Kit
                            </a>
                          </SC.MidiaKitLink>
                        )}
                      </SC.LinksContainer>
                      <SC.ImageLink
                        onClick={() => onClickArtist(artist)}
                        disabled={!artist.name}
                      >
                        {artist.s3_path && (
                          <SC.Img
                            title={artist.name}
                            src={artist.s3_path ? artist.s3_path : ""}
                          />
                        )}
                      </SC.ImageLink>
                    </SC.Artist>
                  </Col>
                ))}
              <a className="only-mobile" onClick={() => skipArtists()}>
                MAIS <span>+/</span>
              </a>
            </Row>
          </Col>
        </Row>
      </Container>
    </SC.ArtistsContainer>
  );
};

export default Artists;
