import styled from "styled-components";

import { Link } from "react-scroll";

export const ScrollLink = styled(Link)`
  cursor: pointer;
`;

export const Copyright = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
`;

export const ScrollBtn = styled.span`
  float: right;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: -45px;
`;

export const FooterContainer = styled.div`
  padding-bottom: 20px;
  margin-top: 160px;
  @media (max-width: 700px) {
    margin-top: 50px;
  }
  a {
    color: #212529;
  }
`;
