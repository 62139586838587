import React, {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";

import { Col, Container, Row } from "react-bootstrap";
import VizSensor from "react-visibility-sensor";
import {
  TiSocialInstagramCircular,
  TiSocialTwitterCircular,
  TiSocialYoutubeCircular,
  TiSocialFacebookCircular,
} from "react-icons/ti";

import { FaXTwitter } from "react-icons/fa6";

import * as SC from "./styles";
import { IBlockProps } from "../../../../@types";

const About: React.FC<IBlockProps> = (props) => {
  const titleRef = useRef<any>();
  const [topPosition, setTopPosition] = useState<number | null>(null);
  const [titlePosition, setTitlePosition] = useState<number | null>(null);
  const [titleOpacity, setTitleOpacity] = useState<number>(0);
  const [sectionTitleOpacity, setSectionTitleOpacity] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useLayoutEffect(() => {
    const onScroll = () => {
      setTopPosition(titleRef?.current?.getBoundingClientRect().top);
    };
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, [titleRef]);

  useEffect(() => {
    const topPercentage = 0;
    const bottomPercentage = 0.3;
    const stoppedPercentage = 1 - topPercentage - bottomPercentage;
    if (topPosition) {
      if (topPosition < window.innerHeight * topPercentage) {
        const topOffset = topPosition - window.innerHeight * topPercentage;
        setTitlePosition(topOffset * 0.5);
        // console.log(" top offset", 1 + topOffset / 300);
        setTitleOpacity(1 + topOffset / 100);
      } else if (topPosition > window.innerHeight * stoppedPercentage) {
        const bottomOffset =
          topPosition - window.innerHeight * stoppedPercentage;
        setTitlePosition(bottomOffset * 0.5);
        // console.log(" bottom offset", 1 - bottomOffset / 300);
        setTitleOpacity(1 - bottomOffset / 250);
      } else {
        setTitlePosition(0);
        setTitleOpacity(1);
      }
    }
  }, [topPosition]);

  return (
    <SC.AboutContainer {...props}>
      <Container>
        <Row>
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={12}>
                <VizSensor
                  minTopValue={200}
                  partialVisibility
                  onChange={(isVisible) => {
                    console.log("isVisible: ", isVisible);
                    setIsVisible(isVisible);
                  }}
                >
                  <SC.Title
                    style={{
                      left: titlePosition || 0,
                      opacity: titleOpacity || 0,
                    }}
                    ref={titleRef}
                  >
                    Warner
                    <br />
                    Music
                    <br />
                    Group
                  </SC.Title>
                </VizSensor>

                <SC.SocialContainer visible={isVisible}>
                  <p>fique por dentro das novidades nos nossos canais</p>
                  <a
                    href="http://www.youtube.com/WarnerBrasil"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TiSocialYoutubeCircular size={34} />
                  </a>
                  <a
                    href="http://twitter.com/WarnerMusicBR"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <SC.TwitterXIcon size={34} />
                  </a>
                  <a
                    href="http://instagram.com/warnermusicbr"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TiSocialInstagramCircular size={34} />
                  </a>
                  <a
                    href="http://www.facebook.com/WarnerMusicBrasil"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TiSocialFacebookCircular size={34} />
                  </a>
                </SC.SocialContainer>
              </Col>
              <Col xs={12} md={12}>
                <p>
                  Warner Music Group é um conglomerado multinacional dedicado à
                  música e ao entretenimento. É formado por importantes selos
                  internacionais, tais como: Asylum, Atlantic, Big Beat,
                  Canvasback, East West, Elektra, Erato, FFRR, Fueled by Ramen,
                  Nonesuch, Parlophone, Reprise, Rhino, Roadrunner, Sire,
                  Spinnin', Warner Records, Warner Classics e Warner Music
                  Nashville, e ainda Warner Chappell, uma das maiores editoras
                  de músicas do mundo.
                </p>
                <p>
                  Com um amplo elenco de estrelas da atualidade e nomes
                  lendários da cena musical mundial, a companhia atua no Brasil
                  desde 1976. Através de importante selos brasileiros, como
                  Continental e Chantecler, é detentora do catálogo de inúmeros
                  e importantíssimos artistas nacionais. Seu cast atual conta
                  com grandes nomes da música.
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={3}>
            <SC.SectionTitleContainer>
              <VizSensor
                onChange={(isVisible) => {
                  console.log("teste de visi", isVisible);
                  setSectionTitleOpacity(isVisible ? 1 : 0);
                }}
              >
                <SC.SectionTitle
                  style={{
                    opacity: sectionTitleOpacity || 0,
                  }}
                >
                  So&nbsp;
                  <br />
                  bre.
                </SC.SectionTitle>
              </VizSensor>
            </SC.SectionTitleContainer>
          </Col>
        </Row>
      </Container>
    </SC.AboutContainer>
  );
};

export default About;
