import styled from "styled-components";
import { vars } from "../../../../styles/vars";

export const YoutubeContainer = styled.div`
  padding-bottom: 80px;
  margin-top: 60px;
  @media (max-width: 700px) {
    margin-top: 30px;
  }
  h1 {
    text-transform: none;
    font-size: 60px;
    letter-spacing: 1px;
    margin-bottom: 100px;

    span {
      color: ${vars.lightGray};
    }
  }
`;

export const SectionTitle = styled.h1`
  font-size: 120px;
  text-align: right;
  line-height: 82.8%;
  color: ${vars.lightGray};
  margin-bottom: 0px;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 700px) {
    display: none;
  }
`;
export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;
