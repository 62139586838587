import React, { useRef, useCallback, useEffect } from "react";

import { Col, Row, Container } from "react-bootstrap";
import SiteTopMenu from "../../components/SiteTopMenu";
import Highlights from "./blocks/Highlights";
import About from "./blocks/About";
import { Website } from "../../styles/globals";
import Artists from "./blocks/Artists";
import Banners from "./blocks/Banners";
import Footer from "./blocks/Footer";
import Contact from "./blocks/Contact";
import YouTubeEmbed from "./blocks/YouTubeEmbed";

const Home: React.FC = () => {
  return (
    <Website>
      <SiteTopMenu />
      <Container className="website" fluid>
        {/* <MainSlider id="releases" /> */}
        <About id="sobre" />
        {/* <Highlights id="destaques" /> */}
        <YouTubeEmbed id="youtube-embed" />
        <Artists id="artistas" />
        {/* <Banners id="banners" /> */}
        <Contact id="contato" />
        {/* <SimpleContact id="contato" /> */}
        <Footer />
      </Container>
    </Website>
  );
};

export default Home;
