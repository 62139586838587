import styled from "styled-components";
import { vars } from "../../../../styles/vars";
import arrow from "../../../../assets/arrow.png";


export const ContactContainer = styled.div`
  margin-bottom: 40px;
  padding-left: 120px;
  padding-right: 120px;
  h1{
    text-transform: none;
    font-size: 60px;
    letter-spacing: 1px;
    margin-bottom: 100px;

    span{
      color: ${vars.lightGray};
    }
  }
  form{
    display: block;
    width: 300px;
    float: right;
  }
  input, select, button{
      border-radius: 30px;
      font-weight: 14px;
      padding: 20px;
      padding-top: 14px;
      padding-bottom: 14px;
      border: none;
      display: block;
      float: right;
      text-align: right;
      margin-bottom: 20px;
    }
    button{
      background: #2D9AFF;
      color: white;
      width: 192px;
      text-align: center;
    }
    input[type="text"]{
      background: black;
      color:#EDEDED;
      width: 570px;
    }
    select{
      color:#303030;
      width: 281px;
      background: #E7E7E7 url(${arrow}) no-repeat scroll calc(5% + 3px) center/14px auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    @media(max-width: 700px) {
      padding: 0px;
      margin-bottom: 0px;
      h1{
        font-size: 30px;
        margin-bottom:30px;
      }
      form{
        width: 100%;
      }
      input, select, button{
          width: 100% !important;
        }

    }
    .inputName{
      width: 230px !important;
    }
    .inputMail{
      width: 280px !important;
    }
    button:disabled
    {
      opacity: 0.4;
    }

`;

export const Logo = styled.img`
  position: absolute;
  width: 143px;
  left: 60px;
  top: 180px;
  @media(max-width: 700px) {
    display: none;
  }
`;
