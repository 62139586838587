import styled from "styled-components";
import { FaXTwitter } from "react-icons/fa6";
import { vars } from "../../../../styles/vars";

interface SocialContainerProps {
  visible?: boolean;
}

export const Title = styled.h1`
  font-size: 100px;
  text-align: right;
  line-height: 82.8%;
  margin-bottom: 40px;
  float: right;
  position: relative;
  @media (max-width: 700px) {
    font-size: 60px;
    margin-bottom: 10px;
  }
`;
export const SectionTitle = styled.h1`
  font-size: 120px;
  text-align: right;
  line-height: 82.8%;
  color: ${vars.lightGray};
  margin-bottom: 0px;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 700px) {
    display: none;
  }
`;
export const AboutContainer = styled.div`
  margin-bottom: 40px;
  margin-top: 150px;
  @media (max-width: 700px) {
    margin-bottom: 40px;
  }

  p {
    text-align: right;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
export const SocialContainer = styled.div<SocialContainerProps>`
  display: inline-flex;
  height: 100%;
  float: right;
  align-items: flex-end;
  padding-bottom: 40px;
  position: relative;
  transition: all 0.5s ease-in-out;
  left: ${({ visible }) => (visible ? 0 : -60)}px;
  p {
    position: absolute;
    width: 209px;
    margin-left: 3px;
    margin-bottom: 46px;
    color: #e1e1e1;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
  svg {
    opacity: 0.1;
    transition: opacity 0.6s ease-in-out;
    &:hover {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }
  a {
    margin-left: ${({ visible }) => (visible ? 20 : 50)}px;
    transition: all 1s ease-in-out;
    color: black;
  }

  @media (max-width: 700px) {
    height: initial;
    display: inline-block;
    margin-top: 20px;
    a {
      margin-left: ${({ visible }) => (visible ? 20 : 30)}px;
    }
  }
`;

export const TwitterXIcon = styled(FaXTwitter)`
  width: 27px;
  height: 27px;
  padding: 4px;
  border: solid 3px;
  border-radius: 100%;
  position: relative;
  top: -3.5px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;
