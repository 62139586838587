import React, { useState } from "react";

import { Col, Row, Container } from "react-bootstrap";

import { FiMenu } from "react-icons/fi";

import * as SC from "./styles";

import logo from "../../assets/warnerlogo@2x.png";

const SiteTopMenu: React.FC = () => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const menuItems = [
    // { name: 'Lançamentos', to: 'lancamentos', offset: 0 },
    { name: "Sobre", to: "sobre", offset: -120 },
    // { name: 'Destaques', to: 'destaques', offset: -120 },
    { name: "Artistas", to: "artistas", offset: -120 },
    // { name: "Projetos", to: "banners", offset: -120 },
    { name: "Contato", to: "contato", offset: 0 },
  ];
  return (
    <SC.Container>
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <SC.Logo src={logo} alt="Warner Music Brasil" />
            <SC.Hamburger
              onClick={() => setMenuIsOpened((opened) => !opened)}
              menuIsOpened={menuIsOpened}
            >
              <FiMenu size="30" />
            </SC.Hamburger>
          </Col>
          <Col xs={12} md={9}>
            <SC.MenuItems menuIsOpened={menuIsOpened}>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <SC.ScrollLink
                    activeClass="active"
                    to={item.to}
                    spy
                    smooth
                    duration={1000}
                    offset={item.offset}
                    onClick={() => setMenuIsOpened(false)}
                  >
                    <span>{item.name}</span>
                    <SC.Line />
                  </SC.ScrollLink>
                </li>
              ))}
            </SC.MenuItems>
          </Col>
        </Row>
      </Container>
    </SC.Container>
  );
};

export default SiteTopMenu;
