import React, { useCallback, useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";

import * as SC from "./styles";
import logo from "../../../../assets/formlogo@2x.png";
import { IBlockProps } from "../../../../@types";
import api from "../../../../services/api";

type MailData = {
  name?: string;
  message?: string;
  mail?: string;
  token?: string;
}

const Contact: React.FC<IBlockProps> = (props) => {

  const [sending, setSending] = useState(false);
  const [mailData, setMailData] = useState<MailData | null>();

  useEffect(() => {
    getWnonceToken();
  }, []);

  const getWnonceToken = () => {
    api
      .get<string>("/getWnonce", {
        params: {},
      })
      .then(({ data }) => {
        console.log("token", data);
        localStorage.setItem("@warner:token", data);

      });

  }

  const sendMail = useCallback(async (e) => {
    if (sending) return;
    e.preventDefault();
    console.log("tentando enviar o e-mail");
    const localToken = localStorage.getItem("@warner:token")?.replace('\n', '');
    if (!localToken) return;

    console.log("mail data", mailData);
    if (!mailData?.mail || !mailData?.name || !mailData?.message) {
      alert("Faltam campos para preencher em seu e-mail.");
      return;
    }

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailData.mail))) {
      alert("Email fornecido é inválido.");
      return;
    }

    setSending(true);
    const response = await api.post("sendMail", { ...mailData, token: localToken });
    localStorage.removeItem("@warner:token");
    setTimeout(() => {
      setSending(false);
      getWnonceToken();
    }, 10000);
    window.alert("E-mail Enviado");
    console.log("response", response);
  }, [sending, mailData]);

  const setMailValues = (name: string, val: string) => {
    if (!mailData) setMailData({});
    const data: MailData = { ...mailData };
    const key: keyof MailData = name as keyof MailData;
    data[key] = val;
    setMailData(data);
  }



  return (
    <SC.ContactContainer {...props}>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h1>
              <span>{"/>"}</span>Fale conosco
            </h1>
            <SC.Logo src={logo} alt="Warner Music Brasil" />
            <form>
              {/* <select name="cars" dir="rtl">
                <option value="" disabled>
                  Escolha o destino
                </option>
                <option value="1">Marketing</option>
                <option value="2">Vagas</option>
                <option value="3">Dúvidas</option>
                <option value="4">Artistas</option>
                <option value="5">Contato para shows</option>
                <option value="6">Parceria</option>
                <option value="7">Liberação de Músicas</option>
              </select> */}
              <input name="name" className="inputName" type="text" placeholder="nome" onChange={(e) => setMailValues(e.target.name, e.target.value)} />
              <input name="mail" className="inputMail" type="text" placeholder="e-mail" onChange={(e) => setMailValues(e.target.name, e.target.value)} />
              <input name="message" type="text" placeholder="...deixe sua mensagem" onChange={(e) => setMailValues(e.target.name, e.target.value)} />
              <button type="submit" onClick={sendMail} disabled={sending}>Enviar</button>
            </form>
          </Col>
        </Row>
      </Container>
    </SC.ContactContainer>
  );
};

export default Contact;
