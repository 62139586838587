import styled from "styled-components";
import arrow from "../../assets/arrow.png";


export const Container = styled.div`
  display: inline-block;
  height: 59px;
  width: 33px;
`;
export const Arrow = styled.div`
  display: inline-block;
  margin-bottom: 0px;
  width: 20px;
  height: 15px;
  background-image: url(${arrow});
  background-size: 15px;
  background-repeat: no-repeat;
  transform: rotate(180deg);
`;

