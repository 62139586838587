import React, { useState, useEffect, useCallback } from "react";
import * as SC from "./styles";

export interface ImageWithTransition {
  src?: string | undefined;
  className?: string;
  title?: string;
}

const ImageWithTransition: React.FC<ImageWithTransition> = ({
  className,
  src,
  title,
}) => {

  const [isLoaded, setIsLoaded] = useState(false);

  const onLoadImage = useCallback(() => {
    console.log("carregou a imagem");
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);
  return (
    <SC.Container className={className}>
      <SC.Img
        isLoaded={isLoaded}
        src={src}
        title={title || "image"}
        alt={title || "image"}
        onLoad={() => onLoadImage()}
      />
    </SC.Container>
  );
};

export default ImageWithTransition;
