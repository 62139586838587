import React from "react";

import * as SC from "./styles";

const ScrollClick: React.FC = () => {
  return (
    <SC.Container>
      <SC.Arrow />
      <SC.Arrow />
      <SC.Arrow />
    </SC.Container>
  )

}

export default ScrollClick;
