import styled, { css } from "styled-components";

interface ImgProps {
  isLoaded?: boolean;
}

export const Container = styled.div`
  position: relative;
`;
export const Img = styled.img<ImgProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; left: 0px;
  transition: all 0.7s ease-in-out;
  opacity: 1;
  ${(props) => {
    if (props.isLoaded)
      return css`
        /* display: block; */
        opacity: 1;
      `;
    return css`
      /* display: none; */
      opacity: 0;
    `;
  }}

`;
