import React from "react";
import GlobalStyles from "./styles/globals";
import Home from "./pages/Home";

const App: React.FC = () => (
  <>
    <Home />
    <GlobalStyles />
  </>
);

export default App;
