import React, { useState, useEffect, useCallback } from "react";

import * as SC from "./styles";

interface HoverLineProps {
  className?: string;
}

const HoverLine: React.FC<HoverLineProps> = ({ className }) => {


  return (
    <SC.Container className={className} >
      {
        Array.apply(null, Array(50)).map((elem, index) => <SC.Bar className="bar" key={index} delay={index * 0.0005} />)
      }
    </SC.Container>
  );
};

export default HoverLine;
