import { AnchorHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import ImageWithTransition from "../../../../components/ImageWithTransition";
import { vars } from "../../../../styles/vars";

interface SwitchProps {
  selected?: boolean;
}
interface LinksContainerProps {
  active?: boolean;
}
interface ImageLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean;
}

export const Title = styled.h1`
  font-size: 100px;
  text-align: right;
  line-height: 82.8%;
  margin-bottom: 40px;
`;
export const SectionTitle = styled.h1`
  font-size: 120px;
  text-align: right;
  line-height: 82.8%;
  color: ${vars.lightGray};
  margin-bottom: 0px;
`;
export const ArtistsContainer = styled.div`
  margin-bottom: 80px;
  h1 {
    font-size: 96px;
    display: inline-block;
    margin-right: 10px;
    font-weight: normal;
    @media(max-width: 700px) {
      font-size: 35px;
      line-height: 28px;
    }

  }
  h4 {
    display: inline-block;
    font-size: 48px;
    font-weight: normal;
    color: ${vars.lightGray};
  }
  h2 {
    text-align: right;
    font-size: 110px;
    font-weight: normal;
    line-height: 94px;
    margin-top: -7px;
    color: ${vars.lightGray};
    position: relative;
    top:100px;
    transition: all 1s ease-in-out;
    @media(max-width: 700px) {
      font-size: 25px;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: right;
    }

    &.active{
      top:0px;
    }

  }
  a {
    font-size: 36px;
    color: black;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-left: 15px;
    margin-bottom: 12px;
    @media(max-width: 700px) {
      font-size: 16px;
      text-align: right;
      padding-right: 15px;
    }
    span {
      color: ${vars.lightGray};
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const Switcher = styled.div`
  font-size: 26px;
  transform: rotate(-90deg);
  transform-origin: 53% -42%;
  position: absolute;
  bottom: 0px;
  margin-bottom: 27px;
  right: 11px;
  text-transform: uppercase;
  @media(max-width: 700px) {
    font-size: 16px;
  }
  div {
    cursor: pointer;
  }
`;

export const Switch = styled.div<SwitchProps>`
  color: black;
  ${(props) => {
    console.log("selected", props.selected);
    if (props.selected)
      return css`
        /* display: block; */
        opacity: 1;
      `;
    return css`
      /* display: none; */
      opacity: 0.1;
    `;
  }}
`;

export const Img = styled(ImageWithTransition)`
  width: 100%;
  height: 100%;
  /* margin-bottom: 25px; */
  /* aspect-ratio: 1/1; */

`;

export const ImageLink = styled.a<ImageLinkProps>`
  width: 100% !important;
  cursor: pointer;
  background-color: #efefef;
  @media(max-width: 700px) {
    /* height: 70px !important; */
  }
  /* display:inline-block !important; */
  position:relative !important;
  padding:0px !important;
  ${(props) => {
    if (props.disabled)
      return css`
        /* display: block; */
        cursor: inherit;
      `;
  }}
`;

export const ArtistName = styled.div`
  position: absolute;
  color: white;
  z-index: 200;
  text-transform: uppercase;
  font-size: 20px;
  background: black;
  padding: 10px;
  transform: scaleX(0);
  transform-origin: left;
  transition: all .2s ease-in-out;
  transition-delay: .1s;
  pointer-events: none;
  span{
    opacity: 0;
    transition: all .1s ease-out;
    transition-delay: 0s;
    user-select: none;
  }
`;

export const LinksContainer = styled.div<LinksContainerProps>`
  position: absolute;
  bottom: 15px;
  right: -2px;
  z-index: 100;
  ${(props) => {
    console.log("active", props.active);
    if (props.active)
      return css`
        /* display: block; */
        opacity: 1;
      `;
    return css`
      /* display: none; */
      opacity: 0;
      pointer-events: none;
    `;
  }}

`;

export const ArtistPageLink = styled.div`
  color: white;
  z-index: 200;
  text-transform: uppercase;
  font-size: 16px !important;
  background: black;
  padding: 10px;
  user-select: none;
  text-align: right;
  cursor:pointer;
  a{
    position: relative;
    font-size: 16px;
    color: white;
  }
  /* transform: scaleX(0); */
  /* transform-origin: left;
  transition: all .2s ease-in-out;
  transition-delay: .1s; */
  /* span{
    opacity: 0;
    transition: all .1s ease-out;
    transition-delay: 0s;
  } */
`;
export const MidiaKitLink = styled.div`
  color: white;
  z-index: 200;
  text-transform: uppercase;
  font-size: 16px !important;
  background: black;
  padding: 10px;
  text-align: right;
  user-select: none;
  margin-top: 2px;
  cursor: pointer;
  a{
    position: relative;
    font-size: 16px;
    color: white;
  }

  /* transform: scaleX(0); */
  /* transform-origin: left;
  transition: all .2s ease-in-out;
  transition-delay: .1s; */
  /* span{
    opacity: 0;
    transition: all .1s ease-out;
    transition-delay: 0s;
  } */
`;

export const Artist = styled.div`
  width: calc(100% + 15px);
  aspect-ratio: 4/4;
  margin-bottom: 10px;
  background-color: #efefef;

  @media(max-width: 700px) {
    /* height: 70px; */
  }
  display: inline-block;
  &:hover{
    ${ArtistName}
    {
      transition: all .2s ease-in-out;
      transition-delay: 0s;
      transform: scaleX(1);
      span{
        opacity: 1;
        transition-delay: .2s;
      }
    }
  }
  @media(max-width: 700px) {
    ${ArtistName}
    {
      background: #00000042;
      transform: scaleX(1);
      padding: 10px;
      padding-top: 2px;
      line-height: 16px;
      span{
        opacity: 1;
        font-size: 10px;
        padding: 0px;
        margin: 0px;
        line-height: 10px;
        position: relative;
      }
    }
  }

`;


