import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import ScrollClick from "../../../../components/ScrollClick";

import * as SC from "./styles";
import { IBlockProps } from "../../../../@types";

const Contact: React.FC<IBlockProps> = (props) => {
  return (
    <SC.FooterContainer {...props}>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <a
              href="https://www.wminewmedia.com/cookies-policy/pt/"
              target="_blank"
            >
              Política de Cookies
            </a>
          </Col>
          <Col xs={12} md={12}>
            <a className="ot-sdk-show-settings" style={{ cursor: "pointer" }}>
              Configurações de cookies
            </a>
          </Col>
          <br />
          <Col xs={12} md={12}>
            <SC.Copyright>
              COPYRIGHT © {new Date().getFullYear()} WARNER MUSIC INC.
            </SC.Copyright>
            <SC.ScrollBtn>
              <SC.ScrollLink
                activeClass="active"
                to="releases"
                spy
                smooth
                duration={1000}
              >
                <ScrollClick />
              </SC.ScrollLink>
            </SC.ScrollBtn>
          </Col>
        </Row>
      </Container>
    </SC.FooterContainer>
  );
};

export default Contact;
