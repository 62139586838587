

import styled, { css } from "styled-components";

interface HoverLineProps {
  active?: boolean;
  delay?: number;
}

export const Container = styled.div`
  width: 100%;
  display: block;
`;

export const Bar = styled.div<HoverLineProps>`
  background: black;
  height: 0px;
  width: 2%;
  display: inline-block;
  transition: height .4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: ${({ delay }) => delay ? delay : 0}s;
`;
