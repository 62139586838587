import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import HoverLine from "../HoverLine";

interface MenuAwareProps {
  menuIsOpened?: boolean;
}

export const Logo = styled.img`
  width: 129px;
  display: inline-block;
  position: relative;
  margin-top: 27px;
  @media(max-width: 700px) {
    width: 90px;
    margin-left: 20px;
    margin-top: 20px;
  }

`;

export const Hamburger = styled.div<MenuAwareProps>`
  position: absolute;
  right: 20px;
  top: 22px;
  display:none;
  @media(max-width: 700px) {
    display: block;
  }
`;

export const Line = styled(HoverLine)`
  margin-left: -20px;
  width: calc(100% + 40px);
  position:absolute;
  bottom: -5px;
`;

export const ScrollLink = styled(Link)`
  cursor: pointer;
  &.active, &:hover
  {
    ${Line}{
      .bar{
        height: 4px;
      }
    }
  }
`;


export const MenuItems = styled.ul<MenuAwareProps>`
  list-style: none;
  display: flex;
  height: 107px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
  li {
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0px;
    }
    a {
      height: 100%;
      color: black;
      display: inline-flex;
      text-transform: uppercase;
      align-items: center;
      position:relative;
    }
  }
  @media(max-width: 700px) {
    height: 40px;
    position: relative;
    background-color: white;
    display: block;
    position: absolute;
    left: -6px;
    width: 100%;
    top: 0px;
    border-top: solid thin #efefef;
    transition: all .2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    /* transition-delay: 2s; */
    ${(props) => {
    if (props.menuIsOpened)
      return css`
        /* visibility: visible; */
        top: 20px;
        opacity: 1;
        pointer-events: initial;
      `;
    return css`
      /* visibility: hidden; */
    `;
  }}
    li{
      display: inline-table;
      width: 100%;
      border-bottom: solid thin #efefef;
      background: white;
      margin-left: 0px;
      margin-right: 0px;
      /* margin-left: -20px; */
      a{
        padding: 30px 40px;
        width: 100%;
      }

    }
  }

`;

export const Container = styled.div`
  /* margin-top: -20px; */
  /* margin-left: -15px; */
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
  overflow: hidden;
  @media(max-width: 700px) {
    padding-bottom: 20px;
    overflow: initial;
    width: calc(100% + 20px)
  }
`;

