import { FiX } from "react-icons/fi";
import styled, { createGlobalStyle, css } from "styled-components";
import mjonir from "../assets/mjonir.png";

interface FormStepProps {
  isCurrentStep?: boolean;
}

export default createGlobalStyle`
  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Conv_SuisseIntl-Regular';
    src: url('fonts/SuisseIntl-Regular.eot');
    src: local('☺'), url('fonts/SuisseIntl-Regular.woff') format('woff'), url('fonts/SuisseIntl-Regular.ttf') format('truetype'), url('fonts/SuisseIntl-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }


  html {
    height: 100%;
  }

  body{
    background-color: #FAFBFE;
    /* background-image: url(${mjonir}); */
    background-repeat: no-repeat;
    /* -webkit-font-smoothing: antialiased; */
    height: 100%;
  }

  body, input, button{
    font-family:'Conv_SuisseIntl-Regular',Sans-Serif;
    font-size: 12px;
  }

  button{
    cursor: pointer;
  }



  #root{
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }

  h4{
    font-weight: 700;
    font-size: 18px;
    color: #6C757D;
  }

  p{
    font-size:14px;
  }

  label{
    font-size: 13px;
    color: #6C757D;
    margin-bottom: 6px;
    display: inline-block;
  }


`;

export const ContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h4`
  font-size: 16px;
  padding: 15px 0px;
  color: #6c757d;
  font-weight: 700;
`;
export const CardTitle = styled.h4`
  font-size: 13px;
  text-transform: uppercase;
  color: #6c757d;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const CloseCardButton = styled(FiX)`
  color: #6c757d;
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 4px;
  cursor: pointer;
`;

export const SquareButton = styled.div`
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  background-color: #727cf5;
  color: white;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 3px;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #4e5bf2;
  }
  svg {
    margin-bottom: -2px;
  }
`;

export const TopActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0px;
`;

export const Card = styled.div`
  border: none;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  margin-bottom: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
  padding: 24px;
  h6 {
    text-transform: uppercase;
    margin: 10px 0;
    font-weight: 700;
    margin-top: 0px;
    color: #6c757d;
  }
`;

export const FormStep = styled.span<FormStepProps>`
  margin-left: 10px;
  margin-right: 10px;
  ${(props) =>
    props.isCurrentStep &&
    css`
      color: #6c757d;
    `}
`;

export const FormSteps = styled.span`
  color: #adb5bd;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 16px;
`;

export const CardWithColums = styled(Card)`
  padding: 24px 9px;
`;

export const Website = styled.div`
  width: 100%;
  /* overflow-x: hidden; */
  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    letter-spacing: -4px;
  }
  .only-mobile{
    display: none;
    @media(max-width: 700px) {
      display: initial;
    }
  }
  .all-but-mobile{

    @media(max-width: 700px) {
      display: none;
    }
  }
  .pointer {
    cursor: pointer;
  }
`;
