import React from "react";
import { Container, Row } from "react-bootstrap";
import { IBlockProps } from "../../../../@types";
import * as SC from "./styles";

const YouTubeEmbed: React.FC<IBlockProps> = () => {
  return (
    <SC.YoutubeContainer>
      <Container>
        <Row>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <iframe
              title="Warner Music Brasil Video"
              src="https://www.youtube.com/embed/gYyQQsmmlyc"
              frameBorder="0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Row>
      </Container>
    </SC.YoutubeContainer>
  );
};

export default YouTubeEmbed;
